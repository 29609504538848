<template>
<div class="row tgju-widgets-row">
    <slot v-if="widgetLoad">
        <div class="text-center p-2  w100-w">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>

        <div class="market-profile-inner">
            <ul style="margin: 0;">
                <li class="li-head" style="border-top: 0;">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">عنوان</div>
                    </div>
                </li>

                <li :class="activeLi == 100 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(100)">
                        <div class="profile-inner-table-name">محاسبه‌گر سود و زیان {{ market_profitloss.info.title }}</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box" style="padding: 0 !important;">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">مقدار سرمایه دلخواه </div>
                            </div>
                            <p style="padding: 0 30px;margin: 0;text-align: justify;">مبلغ سرمایه خود را در فیلد زیر وارد نموده تا مقدار سرمایه حال حاضر شما نسبت به سود و زیان در جدول کناری نمایش داده شود</p>
                            <v-text-field id="datatables-search-box" class="mobile-app-input invest_input" style="margin-bottom: 7px;" :label="market_profitloss.info.market_type == 'local' ? 'مقدار سرمایه به ریال ' : 'مقدار سرمایه به دلار'"></v-text-field>
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">۱ روز</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][1]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][1]['currency'] }}</span></div>
                            </div>
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">۱ هفته</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][6]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][6]['currency'] }}</span></div>
                            </div>
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">۱ ماه</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][24]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][24]['currency'] }}</span></div>
                            </div>
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">۳ ماه</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][72]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][72]['currency'] }}</span></div>
                            </div>
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">۶ ماه</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][144]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][144]['currency'] }}</span></div>
                            </div>
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">۱ سال</div>
                                <div class="profile-inner-table-value">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][188]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][188]['currency'] }}</span></div>
                            </div>
                            <p style="  text-align: justify; color: rgb(102, 102, 102); font-size: 12px; line-height: 2;background: #fff;margin: 10px;padding: 5px 30px 22px;border-radius: 6px;">
                                <span style="display: block;font-size: 18px;font-weight: 600;color: #333;margin-top: 10px;">راهنما</span>
                                در این بخش، فرض بر این می باشد که شما بخشی از دارایی خود را در روزهای گذشته صرف سرمایه گذاری در یکی از شاخص ها کرده اید فرضا 10 میلیون ریال از سرمایه خود را به خرید نیم سکه اختصاص داده اید یا 1000 دلار ارز خریده اید و حال پس از گذشت یک دوره زمانی و سپری شدن تحولات نزولی و صعودی در بازار مربوطه، خواهان این هستید که دریابید این اقدام شما سودآور بوده است یا خیر؟ به عنوان نمونه شما 3 ماه قبل 10 میلیون ریال یا یک میلیون تومان از سرمایه خود را به خرید نیم سکه اختصاص دادید (فرضا 2 نیم سکه 500 هزار تومانی ) و امروز بعد از گذشت 3 ماه، هر نیم سکه به 540 هزار تومان رسیده است لذا ده میلیون ریال شما به ده میلیون و هشتصد هزار ریال تبدیل شده و شما هشتصد هزار ریال یا هشتاد هزار تومان سود به دست آورده اید. به همین روال، بازه های زمانی مختلف از یک روز تا یک سال برای هر شاخص تعریف شده است که به تناسب نیاز یا علاقه می توانید میزان و سمت و سوی تحولات شاخص موردنظرتان را در هریک از این بازه های زمانی مشاهده فرمایید. نکته پایانی آنکه برای شاخص های مربوط به بازار داخلی مبلغ 10 میلیون ریال و برای شاخص های جهانی 1000 دلار به عنوان مبلغ پایه سرمایه، فرض گرفته شده است. 
                            </p>
                        </div> 
                    </div>
                </li>

                
                <!-- <li :class="activeLi == 101 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(101)">
                        <div class="profile-inner-table-name"> مقایسه‌گر سرمایه‌گذاری در {{ market_profitloss.info.title }}</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box" style="padding: 0 !important;">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">مقدار سرمایه دلخواه </div>
                            </div>
                            <div class="filter-box-select profile-mobile-view">
                                <v-select v-if="selectList.length" label="بازه زمانی" class="profile-mobile-view-select mobile-view-select-modal " :items="selectList" item-text="text"  item-value="value" @change="onChange($event)" outlined></v-select>
                            </div>
                            <p style="padding: 0 30px;margin: 0;text-align: justify;">
                                مبلغ سرمایه خود را وارد نمایید تا نتیجه مقایسه آن با سایر بازار ها در مقادیر فعلی جایگزین شود
                            </p>
                            <v-text-field id="datatables-search-box" class="mobile-app-input invest_others_input" style="margin-bottom: 7px;" :label="market_profitloss.info.market_type == 'local' ? 'مقدار سرمایه به ریال' : 'مقدار سرمایه به دلار'"></v-text-field>
                        </div> 
                    </div>
                </li> -->

                <!-- <li class="li-head" style="border-top: 0;">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">نمودارها</div>
                    </div>
                </li> -->


            </ul>
        </div>


        <!-- Forms -->
        <!-- <div class="tgju-widgets-block col-12 ">
            <div class="tgju-widget light has-icon">
                <div class="tgju-widget-title dot m-0">
                    <h2>محاسبه‌گر سود و زیان {{ market_profitloss.info.title }}</h2>
                    <span class="stocks-block-icon"><i class="fa fa-bar-chart"></i></span>
                </div>
            </div>
        </div> -->
        <!-- <div class="col-12 col-md-4 mb-2">
            <div class="tgju-widgets-block">
                <div class="tgju-widget light has-icon" data-target="profile-tour-step-11">
                    <h3 class="tgju-widget-title dot m-0">
                        مقدار سرمایه دلخواه
                    </h3>
                    <div class="tgju-widget-content tgju-widget-forms">
                        <p style="padding-top: 5px;">مبلغ سرمایه خود را در فیلد زیر وارد نموده تا مقدار سرمایه حال حاضر شما نسبت به سود و زیان در جدول کناری نمایش داده شود</p>
                        <input type="text" class="invest_input" value="" :placeholder="market_profitloss.info.market_type == 'local' ? 'مقدار سرمایه خود را به ریال وارد نمایید' : 'مقدار سرمایه خود را به دلار وارد کنید'" style="min-width: 180px; text-align: center">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-8" style="padding: 10px 20px 0 25px !important;">
            <div class="row">
                <div class="col-6 col-md-4 mb-3">
                    <div class="tgju-widgets-block">
                        <div class="tgju-widget light has-icon">
                            <div class="tables-data tables-border-bt-none" data-invest-period="1">
                                <table class="table">
                                    <thead class="text-center">
                                        <tr>
                                            <th>۱ روز</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-padding-lg">
                                        <tr>
                                            <td class="padding-set-num">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][1]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][1]['currency'] }}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 mb-3">
                    <div class="tgju-widgets-block">
                        <div class="tgju-widget light has-icon">
                            <div class="tables-data tables-border-bt-none" data-invest-period="6">
                                <table class="table">
                                    <thead class="text-center">
                                        <tr>
                                            <th>۱ هفته</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-padding-lg">
                                        <tr>
                                            <td class="padding-set-num">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][6]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][6]['currency'] }}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 mb-3">
                    <div class="tgju-widgets-block">
                        <div class="tgju-widget light has-icon">
                            <div class="tables-data tables-border-bt-none" data-invest-period="24">
                                <table class="table">
                                    <thead class="text-center">
                                        <tr>
                                            <th>۱ ماه</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-padding-lg">
                                        <tr>
                                            <td class="padding-set-num">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][24]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][24]['currency'] }}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 mb-3">
                    <div class="tgju-widgets-block">
                        <div class="tgju-widget light has-icon">
                            <div class="tables-data tables-border-bt-none" data-invest-period="72">
                                <table class="table">
                                    <thead class="text-center">
                                        <tr>
                                            <th>۳ ماه</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-padding-lg">
                                        <tr>
                                            <td class="padding-set-num">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][72]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][72]['currency'] }}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 mb-3">
                    <div class="tgju-widgets-block">
                        <div class="tgju-widget light has-icon">
                            <div class="tables-data tables-border-bt-none" data-invest-period="144">
                                <table class="table">
                                    <thead class="text-center">
                                        <tr>
                                            <th>۶ ماه</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-padding-lg">
                                        <tr>
                                            <td class="padding-set-num">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][144]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][144]['currency'] }}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-4 mb-3">
                    <div class="tgju-widgets-block">
                        <div class="tgju-widget light has-icon">
                            <div class="tables-data tables-border-bt-none" data-invest-period="188">
                                <table class="table">
                                    <thead class="text-center">
                                        <tr>
                                            <th>۱ سال</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-padding-lg">
                                        <tr>
                                            <td class="padding-set-num">{{ formatPrice(market_profitloss.invest[market_profitloss.info.item_id][188]['value']) }} <span class="currency-type">{{ market_profitloss.invest[market_profitloss.info.item_id][188]['currency'] }}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- Help -->
        <!-- <div class="tgju-widgets-block col-12 ">
            <div class="tgju-widget light has-icon" data-target="profile-tour-step-13">
                <div class="tgju-widget-title dot m-0">
                    راهنما
                </div>

                <div class="tgju-widget-content p-3">
                    در این بخش، فرض بر این می باشد که شما بخشی از دارایی خود را در روزهای گذشته صرف سرمایه گذاری در یکی از شاخص ها کرده اید فرضا 10 میلیون ریال از سرمایه خود را به خرید نیم سکه اختصاص داده اید یا 1000 دلار ارز خریده اید و حال پس از گذشت یک دوره زمانی و سپری شدن تحولات نزولی و صعودی در بازار مربوطه، خواهان این هستید که دریابید این اقدام شما سودآور بوده است یا خیر؟ به عنوان نمونه شما 3 ماه قبل 10 میلیون ریال یا یک میلیون تومان از سرمایه خود را به خرید نیم سکه اختصاص دادید (فرضا 2 نیم سکه 500 هزار تومانی ) و امروز بعد از گذشت 3 ماه، هر نیم سکه به 540 هزار تومان رسیده است لذا ده میلیون ریال شما به ده میلیون و هشتصد هزار ریال تبدیل شده و شما هشتصد هزار ریال یا هشتاد هزار تومان سود به دست آورده اید. به همین روال، بازه های زمانی مختلف از یک روز تا یک سال برای هر شاخص تعریف شده است که به تناسب نیاز یا علاقه می توانید میزان و سمت و سوی تحولات شاخص موردنظرتان را در هریک از این بازه های زمانی مشاهده فرمایید. نکته پایانی آنکه برای شاخص های مربوط به بازار داخلی مبلغ 10 میلیون ریال و برای شاخص های جهانی 1000 دلار به عنوان مبلغ پایه سرمایه، فرض گرفته شده است.
                </div>
            </div>
        </div> -->

        <!-- <div class="tgju-widgets-block col-12 ">
            <div class="tgju-widget light has-icon">
                <div class="tgju-widget-title dot m-0">
                    <h2> مقایسه‌گر سرمایه‌گذاری در {{ market_profitloss.info.title }}</h2>
                    <span class="stocks-block-icon"><i class="fa fa-bar-chart"></i></span>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-4 mb-3">
            <div class="tgju-widgets-block">
                <div class="tgju-widget light has-icon" data-target="profile-tour-step-14">
                    <h3 class="tgju-widget-title dot m-0">
                        مقدار سرمایه دلخواه
                    </h3>

                    <div class="tgju-widget-content tgju-widget-forms">
                        <select id="invest_select">
                            <option value="1">1 روز</option>
                            <option value="6">1 هفته</option>
                            <option value="24">1 ماه</option>
                            <option value="72" selected="selected">3 ماه</option>
                            <option value="144">6 ماه</option>
                            <option value="188">1 سال</option>
                        </select>

                        <p style="padding-top: 5px;text-align: center;">
                            مبلغ سرمایه خود را وارد نمایید تا نتیجه مقایسه آن با سایر بازار ها در مقادیر فعلی جایگزین شود
                        </p>
                        <input type="text" class="invest_others_input" value="" :placeholder="market_profitloss.info.market_type == 'local' ? 'مقدار سرمایه خود را به ریال وارد نمایید' : 'مقدار سرمایه خود را به دلار وارد کنید'" style="min-width: 180px; text-align: center">
                    </div>
                </div>
            </div>
        </div>
        

        <div class="col-12 col-md-8" data-target="profile-tour-step-15">
            <div class="row" style="padding: 0 5px;">
                <div v-for="(item, index) in market_profitloss.invest_items" :key="index" class="col-6 col-md-6 mb-3 invest-item">
                    <div class="tgju-widgets-block">
                        <div class="tgju-widget light has-icon">
                            <div class="tables-data tables-border-bt-none">
                                <table class="table">
                                    <thead class="text-center">
                                        <tr>
                                            <th>item_title</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-padding-lg">
                                        <tr>
                                            <td class="padding-set-num">TODO <span class="currency-type">TODO</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tgju-widgets-block col-12 col-sm-6 col-md-6 col-lg-3">
            <div class="tgju-widget light has-icon" data-target="profile-tour-step-17">
                <div class="tgju-widget-title dot m-0">
                    <h4>طلا</h4>
                </div>
                <div id="gauge1" class="indicator gauge-chart" style="width: 100%; height: 200px; margin:16px auto -15px;"></div>
            </div>
        </div>
        <div class="tgju-widgets-block col-12 col-sm-6 col-md-6 col-lg-3">
            <div class="tgju-widget light has-icon" data-target="profile-tour-step-18">
                <div class="tgju-widget-title dot m-0">
                    <h4>سکه امامی</h4>
                </div>
                <div id="gauge2" class="indicator gauge-chart" style="width: 100%; height: 200px; margin:16px auto -15px;"></div>
            </div>
        </div>
        <div class="tgju-widgets-block col-12 col-sm-6 col-md-6 col-lg-3">
            <div class="tgju-widget light has-icon" data-target="profile-tour-step-19">
                <div class="tgju-widget-title dot m-0">
                    <h4>بورس</h4>
                </div>
                <div id="gauge3" class="indicator gauge-chart" style="width: 100%; height: 200px; margin:16px auto -15px;"></div>
            </div>
        </div>
        <div class="tgju-widgets-block col-12 col-sm-6 col-md-6 col-lg-3">
            <div class="tgju-widget light has-icon" data-target="profile-tour-step-20">
                <div class="tgju-widget-title dot m-0">
                    <h4>دلار آزاد</h4>
                </div>
                <div id="gauge4" class="indicator gauge-chart" style="width: 100%; height: 200px; margin:16px auto -15px;"></div>
            </div>
        </div> -->
    </slot>
</div>
</template>

<script>
export default {
    name: "MarketProfitlossComponent",
    data() {
        return {
            widgetLoad: true,
            market_profitloss: [],
            activeLi: 100
        }
    },
    mounted() {
        this.getProfileProfitloss();
    },
    methods: {

        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }

        },
        getProfileProfitloss() {
            this.$helpers
                .makeRequest("GET", `/market/profile-profitloss/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_profitloss = api_response.data.response.detail.response;
                        this.$parent.title = this.market_profitloss.info.title + ' - ' + this.$route.meta.title;
                        this.widgetLoad = false;
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
}
</script>